import { IBaseApiRequest } from '@api';
import { AxiosResponse } from 'axios';
import { ICurrentUser } from '@entities';

interface IPing extends IBaseApiRequest {}
export async function ping({ api }: IPing) {
  return api.get('ping');
}

interface IGetMe extends IBaseApiRequest {}

export async function getMe({ api }: IGetMe) {
  let response = (await api.get('current_user')) as AxiosResponse<{
    data: ICurrentUser;
  }>;

  return response.data.data;
}
