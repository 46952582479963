import { useCurrentUser } from '@hooks';

export function useAvatar() {
  let { data, isLoading, error } = useCurrentUser();

  let user = {
    firstName: data?.user.firstName,
    secondName: data?.user.lastName,
  };

  return {
    fullName: `${user?.firstName} ${user?.secondName}`,
    isLoading,
  };
}
